import React, { lazy, Suspense } from 'react'

import {
  Route,
  Switch,
  Redirect
} from 'react-router'

const Home = (
  lazy(() => import('@/components/Home'))
)

const Login = (
  lazy(() => import('@/components/Login'))
)

const Mobile = (
  lazy(() => import('@/components/Mobile'))
)

const routes = (
  <Suspense fallback={<div />}>
    <Switch>
      <Route path='/login' component={Login} exact sensitive />
      <Route path='/home' component={Home} sensitive />
      <Route path='/mobile' component={Mobile} sensitive />
      <Route path='*'>
        <Redirect to='/home' />
      </Route>
    </Switch>
  </Suspense>
)

export default routes