export const GET_USERS = 'GET_USERS'
export const GET_USERS_FULFILLED = 'GET_USERS_FULFILLED'
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED'
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED'

export const CREATE_USERS = 'CREATE_USERS'
export const CREATE_USERS_FULFILLED = 'CREATE_USERS_FULFILLED'
export const CREATE_USERS_REJECTED = 'CREATE_USERS_REJECTED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED'
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED'

export const RESTORE_USER = 'RESTORE_USER'
export const RESTORE_USER_FULFILLED = 'RESTORE_USER_FULFILLED'
export const RESTORE_USER_REJECTED = 'RESTORE_USER_REJECTED'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED'
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED'