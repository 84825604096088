import { BehaviorSubject, of } from 'rxjs'
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { switchMap } from 'rxjs/operators'
import { has } from 'lodash'

import AuthReducer from '@/redux/reducers/auth'

export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (has(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

let reducerRegistry =  {}

const reducer$ = new BehaviorSubject()

export const reducerRegistry$ = reducer$.pipe(
  switchMap(reducer => {
    reducerRegistry = { ...reducerRegistry, ...reducer }
    return of(combineReducers(reducerRegistry))
  })
)

const createRootReducer = history => {
  const initialReducer = {
    router: connectRouter(history),
    AuthReducer: createReducer(AuthReducer.initialState, AuthReducer.handlers)
  }
  reducerRegistry$.next(initialReducer)
  return combineReducers(initialReducer)
}   

export default createRootReducer