import {
  catchError,
  filter,
  map,
  mergeMap
} from 'rxjs/operators'
import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import axios from 'axios'
import {
  AUTH,
  AUTH_FULFILLED,
  AUTH_REJECTED,
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED
} from '@/types'
import {
  auth,
  login,
  logout,
} from '@/api'

const authenticateEpics = {
  auth: () => ({
    type: AUTH
  }),

  authFulfilled: payload => ({
    type: AUTH_FULFILLED,
    payload
  }),

  authError: error => ({
    type: AUTH_REJECTED,
    payload: error
  }),

  authEpic: action$ => action$.pipe(
    ofType(AUTH),
    mergeMap(() => from(auth()).pipe(
      map(user => authenticateEpics.authFulfilled(user)),
      catchError(error => of(authenticateEpics.authError(error)))
    ))
  ),

  login: ({ email, password, remember })=> ({
    type: LOGIN,
    payload: { email, password, remember }
  }),

  loginFulfilled: () => ({
    type: LOGIN_FULFILLED
  }),

  loginError: error => ({
    type: LOGIN_REJECTED,
    payload: error
  }),

  loginEpic: action$ => action$.pipe(
    ofType(LOGIN),
    filter(action => action.payload !== undefined),
    mergeMap(action => {
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      return from(login(action.payload, source)).pipe(
        map(() => authenticateEpics.loginFulfilled()),
        catchError(error => of(authenticateEpics.loginError(error)))
      )
    })
  ),

  logout: () => ({
    type: LOGOUT
  }),

  logoutFulfilled: () => ({
    type: LOGOUT_FULFILLED
  }),

  logoutError: error => ({
    type: LOGOUT_REJECTED,
    payload: error
  }),

  logoutEpic: action$ => action$.pipe(
    ofType(LOGOUT),
    mergeMap(() => from(logout()).pipe(
      map(() => authenticateEpics.logoutFulfilled()),
      catchError(error => of(authenticateEpics.logoutError(error)))
    ))
  )
}

export default authenticateEpics
