import axios from 'axios'
import { get } from 'lodash'
import { history } from '@/redux/store'

axios.interceptors.response.use(
  response => response,
  error => {
    const errorCode = get(error, 'response.status', -1)
    if (errorCode === 401 && history.location.pathname !== '/login') {
      history.push('/login')
    }
    return Promise.reject(error)
  }
)