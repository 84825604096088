import { BehaviorSubject, iif, empty } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

let epicRegistry = []
export const epic$ = new BehaviorSubject(combineEpics(...epicRegistry))

const rootEpic = (action$, state$) =>
  epic$.pipe(
    mergeMap(epic => 
      iif(() => {
        const isExist = epicRegistry.includes(epic)
        if (!isExist) {
          epicRegistry = [...epicRegistry, epic]
        }
        return isExist
      }, empty(), epic(action$, state$))
    )
  )

export default rootEpic
