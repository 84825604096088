import Immutable from 'immutable'
import { isObject } from 'lodash'

import {
  AUTH_FULFILLED,
  AUTH_REJECTED,
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
} from '@/types'
import { ROLES } from '@/constants'

const initialState = new Immutable.Map({
  error: null,
  isAdmin: false,
  isAuthenticated: false,
  isLoggingOut: false,
  isVerifying: false,
  user: null,
})

const isAdmin = user => isObject(user) && user.role === ROLES.ADMIN

export default {
  initialState, 
  handlers: {
    [AUTH_FULFILLED]: (state, action) => state.merge({
      isAdmin: isAdmin(action.payload),
      isAuthenticated: true,
      user: action.payload,
    }),
    [AUTH_REJECTED]: state => state.merge({
      isAdmin: false,
      isAuthenticated: false,
      user: null
    }),
    [LOGIN]: state => state.merge({
      error: null,
      isVerifying: true
    }),
    [LOGIN_FULFILLED]: state => state.merge({
      isAuthenticated: true,
      isVerifying: false
    }),
    [LOGIN_REJECTED]: (state, action) => state.merge({
      error: action.payload,
      isAuthenticated: false,
      isVerifying: false
    }),
    [LOGOUT]: state => state.merge({
      error: null,
      isLoggingOut: true,
    }),
    [LOGOUT_FULFILLED]: state => state.merge({
      isAuthenticated: false,
      isLoggingOut: false,
      user: null
    }),
    [LOGOUT_REJECTED]: (state, action) => state.merge({
      error: action.payload,
      isAuthenticated: false,
      isLoggingOut: false
    })
  }
}
