import axios from 'axios'
import { map, forEach } from 'lodash'

import './interceptor'
import ERROR_MSG from './errorMsg.json'

const PATH = process.env.REACT_APP_API_PATH || '/api'

const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1])
    })
    return result.join('')
  }
}

const ENDPOINTS = {
  AUTH: '/auth',
  DICOM_PATIENTS: '/dicom/patients',
  DICOM_PATIENT_SERIES: ttl`/dicom/patients/${'id'}`,
  DICOM_SERIES: ttl`/dicom/series/${'id'}`,
  DICOM_INSTANCE_FILE: ttl`/dicom/instances/${'id'}/file`,
  LOCK_RECORD: ttl`/records/${'id'}/lock`,
  LOGIN: '/login',
  LOGOUT: '/logout',
  OPTIONS: '/options',
  QUESTIONSET: ttl`/questionSets/${'id'}`,
  QUESTIONSETS: '/questionSets',
  RECORD: ttl`/records/${'id'}`,
  RECORDS: '/records',
  UNLOCK_RECORD: ttl`/records/${'id'}/unlock`,
  USER: ttl`/users/${'id'}`,
  USERS: '/users',
}

const makeURL = endpoint => `${PATH}${endpoint}`

const extractResponse = res => res.data.data

const errorHandler = err => {
  if (err.response) {
    const msg = err.response.data.error
    const status = err.response.status
    const newError =  new Error(msg)
    newError.status = status
    throw newError
  } else {
    throw err
  }
}

export const auth = async () => {
  try {
    const url = makeURL(ENDPOINTS.AUTH)
    return extractResponse(await axios.post(url))
  } catch (err) {
    errorHandler(err)
  }
}

export const login = async ({ email, password, remember }) => {
  const url = makeURL(ENDPOINTS.LOGIN)
  const body = { email, password, remember }
  try {
    await axios.post(url, body)
  } catch (err) {
    if (err.response) {
      const { status } = err.response
      if (status === 401) {
        throw new Error(ERROR_MSG.LOGIN_401)
      } else {
        const msg = err.response.data.error
        throw new Error(msg)
      }
    } else {
      throw err
    }
  }
}

export const logout = async () => {
  const axiosNoInterceptors = axios.create()
  const url = makeURL(ENDPOINTS.LOGOUT)
  try {
    await axiosNoInterceptors.post(url)
  } catch (err) {
    errorHandler(err)
  }
}

export const createUser = async ({ email, password, lastName, firstName, deleted }) => {
  const url = makeURL(ENDPOINTS.USERS)
  const body = { email, password, lastName, firstName, deleted }
  try{
    const response = await axios.post(url, body)
    return extractResponse(response)
  } catch (err) {
    if (err.response) {
      const { status } = err.response
      if (status === 409) {
        throw new Error(ERROR_MSG.REGISTER_409)
      } else {
        const msg = err.response.data.error
        throw new Error(msg)
      }
    } else {
      throw err
    }
  }
}

export const deleteUser = async id => {
  const url = makeURL(ENDPOINTS.USER({ id }))
  try {
    await axios.delete(url)
  } catch (err) {
    errorHandler(err)
  }
}

// const getQuestionSet = async id => {
//   try {
//     const url = makeURL(ENDPOINTS.QUESTIONSET({ id }))
//     return extractResponse(await axios.get(url))
//   } catch (err) {
//     errorHandler(err)
//   }
// }

const getQuestionSets = async () => {
  try {
    const url = makeURL(ENDPOINTS.QUESTIONSETS)
    return extractResponse(await axios.get(url))
  } catch (err) {
    errorHandler(err)
  }
}

const getOptions = async () => {
  try {
    const url = makeURL(ENDPOINTS.OPTIONS)
    return extractResponse(await axios.get(url))
  } catch (err) {
    errorHandler(err)
  }
}

export const getConfig = async () => {
  try {
    const promises = []
    promises.push(getQuestionSets())
    promises.push(getOptions())
    const [questionSets, options] = await Promise.all(promises)
    return {
      questionSets,
      options
    }
  } catch (err) {
    errorHandler(err)
  }
}

export const createRecord = async ({ answer, mrn, questionSetId }) => {
  const url = makeURL(ENDPOINTS.RECORDS)
  const body = { answer, mrn, questionSet: questionSetId }
  try {
    return extractResponse(await axios.post(url, body))
  } catch (err) {
    errorHandler(err)
  }
}

export const createUsers = async users => {
  const url = makeURL(ENDPOINTS.USERS)
  const data = { users }
  const params = {
    bulk: true
  }
  try {
    return extractResponse(await axios.post(url, data, {
      params
    }))
  } catch (err) {
    errorHandler(err)
  }
}

export const getPatients = async () => {
  const url = makeURL(ENDPOINTS.DICOM_PATIENTS)
  try {
    const params = {
      expand: true
    }
    return extractResponse(await axios.get(url, { params }))
  } catch (err) {
    errorHandler(err)
  }
}

export const getPatientSeries = async id => {
  const url = makeURL(ENDPOINTS.DICOM_PATIENT_SERIES({ id }))
  try {
    const patientSeries = extractResponse(await axios.get(url))
    forEach(patientSeries, (series, index) => {
      series.instanceUrls = map(series.Instances, id => makeURL(ENDPOINTS.DICOM_INSTANCE_FILE({ id })))
      patientSeries[index] = series
    })
    return patientSeries
  } catch (err) {
    errorHandler(err)
  }
}

export const getSeries = async id => {
  const url = makeURL(ENDPOINTS.DICOM_SERIES({ id }))
  
  try {
    const series = extractResponse(await axios.get(url))
    series.instanceUrls = map(series.Instances, id => makeURL(ENDPOINTS.DICOM_INSTANCE_FILE({ id })))
    return series
  } catch (err) {
    errorHandler(err)
  }
}

export const getRecords = async () => {
  const url = makeURL(ENDPOINTS.RECORDS)
  try {
    const params = {
      populates: 'user',
      excludes: 'answer'
    }
    const response = extractResponse(await axios.get(url, { params }))
    return response
  } catch (err) {
    errorHandler(err)
  }
}

export const getRecord = async id => {
  const url = makeURL(ENDPOINTS.RECORD({ id }))
  try {
    const promises = []
    const params = {
      populates: 'questionSet'
    }
    promises.push(axios.get(url, { params }))
    promises.push(getOptions())

    let [response, options] = await Promise.all(promises)
    response = extractResponse(response)
    response.options = options
    return response
  } catch (err) {
    errorHandler(err)
  }
}

export const getUsers = async () => {
  const url = makeURL(ENDPOINTS.USERS)
  try {
    const response = await axios.get(url)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export const lockRecord = async id => {
  const url = makeURL(ENDPOINTS.LOCK_RECORD({ id }))
  try {
    const record = extractResponse(await axios.post(url))
    return record
  } catch (err) {
    errorHandler(err)
  }
}

export const unlockRecord = async id => {
  const url = makeURL(ENDPOINTS.UNLOCK_RECORD({ id }))
  try {
    const record = extractResponse(await axios.post(url))
    return record
  } catch (err) {
    errorHandler(err)
  }
}

export const updateRecord = async ({ answer, mrn,  id }) => {
  const url = makeURL(ENDPOINTS.RECORD({ id }))
  try {
    const body = {
      answer,
      mrn
    }
    const response = await axios.put(url, body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export const updateUser = async data => {
  const { id } = data
  const url = makeURL(ENDPOINTS.USER({ id }))
  delete data.id
  try {
    const response = await axios.put(url, data)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}
