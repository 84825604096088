export const CREATE_RECORD = 'CREATE_RECORD'
export const CREATE_RECORD_FULFILLED = 'CREATE_RECORD_FULFILLED'
export const CREATE_RECORD_REJECTED = 'CREATE_RECORD_REJECTED'

export const GET_RECORDS = 'GET_RECORDS'
export const GET_RECORDS_FULFILLED = 'GET_RECORDS_FULFILLED'
export const GET_RECORDS_REJECTED = 'GET_RECORDS_REJECTED'

export const GET_RECORD = 'GET_RECORD'
export const GET_RECORD_FULFILLED = 'GET_RECORD_FULFILLED'
export const GET_RECORD_REJECTED = 'GET_RECORD_REJECTED'

export const UPDATE_RECORD = 'UPDATE_RECORD'
export const UPDATE_RECORD_FULFILLED = 'UPDATE_RECORD_FULFILLED'
export const UPDATE_RECORD_REJECTED = 'UPDATE_RECORD_REJECTED'

export const LOCK_RECORD = 'LOCK_RECORD'
export const LOCK_RECORD_FULFILLED = 'LOCK_RECORD_FULFILLED'
export const LOCK_RECORD_REJECTED = 'LOCK_RECORD_REJECTED'

export const UNLOCK_RECORD = 'UNLOCK_RECORD'
export const UNLOCK_RECORD_FULFILLED = 'UNLOCK_RECORD_FULFILLED'
export const UNLOCK_RECORD_REJECTED = 'UNLOCK_RECORD_REJECTED'