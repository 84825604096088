export const LOGIN = 'LOGIN'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'
export const LOGIN_CANCEL = 'LOGIN_CANCEL'
export const LOGIN_CANCEL_FULFILLED = 'LOGIN_CANCEL_FULFILLED'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED'

export const AUTH = 'AUTH'
export const AUTH_FULFILLED = 'AUTH_FULFILLED'
export const AUTH_REJECTED = 'AUTH_REJECTED'