import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { hot } from 'react-hot-loader/root'
import { useDispatch, useSelector } from 'react-redux'

import '@/shared/styles/index.scss'
import authEpics from '@/redux/epics/auth'
import routes from '@/routes/root'
import { epic$ } from '@/redux/epics'

epic$.next(authEpics.authEpic)

const Root = props => {
  const { history } = props
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.getIn(['AuthReducer', 'isAuthenticated']))
  const prevIsAuthenticated = useRef(isAuthenticated)

  useEffect(() => {
    history.listen(() => {
      dispatch(authEpics.auth())
    })
  }, [history])

  useEffect(() => {
    dispatch(authEpics.auth())
  }, [])

  useEffect(() => {
    if (!isAuthenticated && prevIsAuthenticated.current) {
      history.push('/login')
    }
    prevIsAuthenticated.current = isAuthenticated
  }, [isAuthenticated])

  if (!props) {
    return null
  } else {
    return (
      <ConnectedRouter history={history}>
        {
          routes
        }
      </ConnectedRouter>
    )
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired
}

export default hot(Root)
